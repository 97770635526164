import * as actions from '../actions/sidebar';

export default function reducer(state, action) {
  switch (action.type) {
    case actions.SIDEBAR_SET:
      return action.value;

    default:
      throw new Error(`Unknown sidebar action: ${action.type}`);
  }
}
