import React from 'react';
import { useInView } from 'react-intersection-observer';
import { CCard, CCardBody, CLink, CSpinner } from '@coreui/react';

import VideoOverview from './VideoOverview';
import ContentContainer from './ContentContainer';

import { VIDEO_READY } from '../constants/videoStatus';

import { useVideos } from '../hooks';

const Library = () => {
  const { videos, loadingVideos, allVideosLoaded, loadNextVideos } =
    useVideos();
  const { inView, ref: thresholdRef } = useInView({
    threshold: 1,
    skip: allVideosLoaded,
  });
  let content;

  React.useEffect(() => {
    if (inView) {
      loadNextVideos();
    }
  }, [inView]);

  if (videos.length === 0 && loadingVideos) {
    content = (
      <div className="text-center">
        <CSpinner />
      </div>
    );
  } else if (videos.length === 0 && !loadingVideos) {
    content = (
      <div className="text-center">
        <p className="font-4xl">👋</p>
        <p className="font-lg">
          You have no videos yet. Are you ready to add some?
        </p>
        <p>
          <CLink className="btn btn-primary" to="/upload-video">
            Let&apos;s do it
          </CLink>
        </p>
      </div>
    );
  } else {
    content = (
      <>
        {videos.map((video) => (
          <div className="position-relative" key={video.id}>
            <VideoOverview
              name={video.name}
              status={video.status}
              uploadDate={video.uploadDate}
              thumbnail={video.thumbnail}
              duration={video.duration}
            />
            {video.status === VIDEO_READY && (
              <CLink
                className="stretched-link"
                to={`/library/video/${video.id}`}
              />
            )}
          </div>
        ))}
        <div ref={thresholdRef}></div>
        <div className="text-center" hidden={!loadingVideos}>
          <CSpinner />
        </div>
      </>
    );
  }

  return (
    <ContentContainer>
      <h3>Videos</h3>
      <CCard>
        <CCardBody>{content}</CCardBody>
      </CCard>
    </ContentContainer>
  );
};

export default Library;
