import React from 'react';
import { Formik } from 'formik';
import { CForm, CButton, CRow, CCol } from '@coreui/react';

import withFormResult from './withFormResult';

import * as subscriptions from '../constants/subscription';

import { usePlanSubscription } from '../hooks';

import { cancelPlanSubscription } from '../services/planSubscription';

const CancelPlanSubscriptionForm = ({
  setSuccessResult,
  setErrorResult,
  resetResult,
}) => {
  const { planSubscription, refreshPlanSubscription } = usePlanSubscription();

  const handleFormSubmit = async () => {
    resetResult();

    try {
      await cancelPlanSubscription();
      const sub = await refreshPlanSubscription();

      if (sub.status === subscriptions.ACTIVE) {
        setSuccessResult(
          'Plan subscription renewal was successfully canceled.'
        );
      } else {
        setSuccessResult('Plan subscription was successfully canceled.');
      }
    } catch (error) {
      setErrorResult(
        'Apologies! Error occurred during the cancelation, our team was notified.'
      );
    }
  };

  return (
    <div>
      <p>Are you sure you want to cancel your plan subscription?</p>
      <Formik initialValues={{}} onSubmit={handleFormSubmit}>
        {({ isSubmitting, handleSubmit }) => (
          <CForm onSubmit={handleSubmit}>
            <CRow alignHorizontal="center">
              <CCol>
                <CButton
                  type="submit"
                  className="d-block w-100"
                  disabled={
                    isSubmitting ||
                    planSubscription?.status === subscriptions.CANCELED
                  }
                >
                  Cancel
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        )}
      </Formik>
    </div>
  );
};

export default withFormResult(CancelPlanSubscriptionForm);
