import React from 'react';
import {
  CBreadcrumb,
  CBreadcrumbItem,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CLink,
  CRow,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CSpinner,
  CAlert,
  CInput,
} from '@coreui/react';
import * as Sentry from '@sentry/nextjs';
import Head from 'next/head';

import config from '../config';

import ContentContainer from './ContentContainer';
import PlayerPreview from './PlayerPreview';
import Player from './Player';
import PlayerPlaybackSettings from './PlayerPlaybackSettings';
import PlayerThumbnailSettings from './PlayerThumbnailSettings';
import PlayerControlsSettings from './PlayerControlsSettings';
import PlayerColorsSettings from './PlayerColorsSettings';
import VideoEmbedCodeOverlay from './VideoEmbedCodeOverlay';
import VideoLinkOverlay from './VideoLinkOverlay';
import DeleteVideoOverlay from './DeleteVideoOverlay';

import { ACCOUNT_ACTIVE } from '../constants/accountStatus';

import { User } from '../contexts/user';
import { OverlayDispatch } from '../contexts/overlay';

import { OVERLAY_OPENED } from '../actions/overlay';

import { useAccountStatus, useGtag } from '../hooks';

import {
  saveVideoSettings,
  getVideoInfo,
  getVideoSettings,
  uploadThumbnail,
} from '../services/video';

import { isEqualObject } from '../utils/component';

const VIDEO_MAX_PREFERRED_HEIGHT = 568;

const PlayerWrapper = ({
  videoWidth,
  videoHeight,
  children,
  ...passThroughProps
}) => {
  return (
    <div
      className="player-wrapper"
      style={{
        maxWidth: `${
          (videoWidth / videoHeight) * VIDEO_MAX_PREFERRED_HEIGHT
        }px`,
      }}
      {...passThroughProps}
    >
      {children}
    </div>
  );
};

const SectionHeading = ({ children }) => {
  return <div className="mb-2 font-lg">{children}</div>;
};

const VideoDetail = ({ id }) => {
  const user = React.useContext(User);
  const overlayDispatch = React.useContext(OverlayDispatch);
  const accountStatus = useAccountStatus();
  const gtag = useGtag();
  const [videoInfo, setVideoInfo] = React.useState(null);
  const [currentSettings, setCurrentSettings] = React.useState(null);
  const [settings, setSettings] = React.useState(null);
  const [thumbnailFile, setThumbnailFile] = React.useState(null);
  const [isSavingSettings, setIsSavingSettings] = React.useState(false);
  const [savingSettingsResult, setSavingSettingsResult] = React.useState(null);
  const videoUrl = `${config.videoPageUrl}/${id}`;

  const openVideoPageEmbedOverlay = () => {
    overlayDispatch({
      type: OVERLAY_OPENED,
      overlay: VideoEmbedCodeOverlay,
      props: {
        videoWidth: videoInfo.width,
        videoHeight: videoInfo.height,
        videoName: videoInfo.name,
        videoUrl,
      },
    });
  };

  const openVideoLinkOverlay = () => {
    overlayDispatch({
      type: OVERLAY_OPENED,
      overlay: VideoLinkOverlay,
      props: {
        videoUrl,
      },
    });
  };

  const openDeleteVideoOverlay = () => {
    overlayDispatch({
      type: OVERLAY_OPENED,
      overlay: DeleteVideoOverlay,
      props: {
        videoId: videoInfo.id,
      },
    });
  };

  const handleSettingsChange = React.useCallback((values) => {
    setSettings((s) => {
      if (!s) {
        return s;
      }

      const newSettings = { ...s, ...values };

      if (newSettings.playButtonHidden && newSettings.controlBarHidden) {
        newSettings.autoplay = true;
      }

      if (newSettings.autoplay) {
        newSettings.muted = true;
      }

      return newSettings;
    });
  }, []);

  const handleSettingsSaving = async () => {
    setSavingSettingsResult(null);
    setIsSavingSettings(true);

    try {
      let thumbnail;

      if (thumbnailFile) {
        thumbnail = await uploadThumbnail({
          uid: user.uid,
          videoId: videoInfo.id,
          file: thumbnailFile,
        });
      } else {
        thumbnail = settings.thumbnail;
      }

      await saveVideoSettings({
        videoId: id,
        settings: { ...settings, thumbnail },
      });

      setIsSavingSettings(false);
      setSavingSettingsResult('success');
      setThumbnailFile(null);
    } catch (error) {
      setIsSavingSettings(false);
      setSavingSettingsResult('error');
      setThumbnailFile(null);

      Sentry.captureException(error);
    }
  };

  React.useEffect(() => {
    let thumbnail;

    if (thumbnailFile) {
      const thumbnailImg = new Image();

      thumbnail = URL.createObjectURL(thumbnailFile);
      thumbnailImg.onload = () => {
        URL.revokeObjectURL(thumbnail);
      };
      thumbnailImg.src = thumbnail;
    } else {
      thumbnail = currentSettings?.thumbnail;
    }

    handleSettingsChange({ thumbnail });
  }, [thumbnailFile, currentSettings?.thumbnail, handleSettingsChange]);

  React.useEffect(() => {
    getVideoSettings({ videoId: id }).then((s) => {
      setCurrentSettings(s);
      setSettings(s);
    });
  }, [id]);

  React.useEffect(() => {
    if (user.uid) {
      getVideoInfo({ videoId: id, uid: user.uid }).then(setVideoInfo);
    }
  }, [id, user.uid]);

  React.useEffect(() => {
    if (savingSettingsResult) {
      getVideoSettings({ videoId: id }).then((s) => {
        setCurrentSettings(s);
        setSettings(s);
      });
      getVideoInfo({ videoId: id, uid: user.uid }).then(setVideoInfo);
    }
  }, [savingSettingsResult]);

  React.useEffect(() => {
    if (savingSettingsResult === 'success') {
      gtag('event', 'video_settings_change', {
        video_id: id,
      });
    }
  }, [savingSettingsResult, gtag, id]);

  if (!settings || !videoInfo) {
    return <CSpinner />;
  }

  return (
    <>
      <Head>
        <title>{videoInfo.name} | Wolvido</title>
      </Head>

      <ContentContainer>
        <CCard>
          <CCardBody>
            <CRow className="mb-3" alignVertical="items-center">
              <CCol md="6">
                <CBreadcrumb className="p-0 m-0 border-0">
                  <CBreadcrumbItem>
                    <CLink to="/library">Library</CLink>
                  </CBreadcrumbItem>
                  <CBreadcrumbItem active>{videoInfo.name}</CBreadcrumbItem>
                </CBreadcrumb>
              </CCol>

              <CCol md="6">
                <div className="d-flex justify-content-end">
                  <CDropdown className="mr-3 btn-group">
                    <CDropdownToggle>Actions</CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem onClick={openVideoPageEmbedOverlay}>
                        Get page embed code
                      </CDropdownItem>
                      <CDropdownItem onClick={openVideoLinkOverlay}>
                        Get video link
                      </CDropdownItem>
                      <CDropdownDivider />
                      <CDropdownItem
                        className="text-danger"
                        onClick={openDeleteVideoOverlay}
                      >
                        Delete video
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>

                  {accountStatus === ACCOUNT_ACTIVE && (
                    <CButton
                      color="primary"
                      disabled={isEqualObject(settings, currentSettings)}
                      onClick={handleSettingsSaving}
                    >
                      Save changes{' '}
                      <CSpinner size="sm" hidden={!isSavingSettings} />
                    </CButton>
                  )}
                </div>
              </CCol>
            </CRow>

            {savingSettingsResult === 'success' && (
              <CAlert color="success" closeButton>
                New settings were successfully saved.
              </CAlert>
            )}
            {savingSettingsResult === 'error' && (
              <CAlert color="danger" closeButton>
                Error occurred during saving the settings.
              </CAlert>
            )}

            <CAlert color="info">
              The video player below has always the default settings. You can
              see the current settings in the{' '}
              <a
                href="#player-preview"
                className="alert-link"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById('player-preview')
                    .scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Player preview
              </a>{' '}
              or on the{' '}
              <CLink className="alert-link" href={videoUrl} target="_blank">
                video page
              </CLink>
              .
            </CAlert>

            <PlayerWrapper
              videoWidth={videoInfo.width}
              videoHeight={videoInfo.height}
            >
              <Player
                src={videoInfo.url}
                videoWidth={videoInfo.width}
                videoHeight={videoInfo.height}
              />
            </PlayerWrapper>

            <div>
              <section className="mt-5">
                <SectionHeading>Name</SectionHeading>

                <div>
                  <CInput
                    value={settings.name}
                    onChange={(e) =>
                      handleSettingsChange({ name: e.target.value })
                    }
                    aria-label="Video name"
                    maxLength={64}
                    required
                  />
                </div>
              </section>

              <section className="mt-5">
                <SectionHeading>Playback</SectionHeading>

                <PlayerPlaybackSettings
                  muted={settings.muted}
                  autoplay={settings.autoplay}
                  loop={settings.loop}
                  autoplayDisabled={
                    settings.playButtonHidden && settings.controlBarHidden
                  }
                  onChange={handleSettingsChange}
                />
              </section>

              <section className="mt-5">
                <SectionHeading>Thumbnail</SectionHeading>

                <PlayerThumbnailSettings
                  onChange={setThumbnailFile}
                  onReset={() => setThumbnailFile(null)}
                />
              </section>

              <section className="mt-5">
                <SectionHeading>Controls</SectionHeading>

                <PlayerControlsSettings
                  playButtonHidden={settings.playButtonHidden}
                  controlBarHidden={settings.controlBarHidden}
                  onChange={handleSettingsChange}
                />
              </section>

              <section className="mt-5">
                <SectionHeading>Colors</SectionHeading>

                <PlayerColorsSettings
                  colorPlayButton={settings.colorPlayButton}
                  colorControlBar={settings.colorControlBar}
                  onChange={handleSettingsChange}
                />
              </section>

              <section className="mt-5" id="player-preview">
                <SectionHeading>Player preview</SectionHeading>

                <PlayerWrapper
                  videoWidth={videoInfo.width}
                  videoHeight={videoInfo.height}
                >
                  <PlayerPreview
                    videoWidth={videoInfo.width}
                    videoHeight={videoInfo.height}
                    thumbnailUrl={settings.thumbnail}
                    playButtonHidden={settings.playButtonHidden}
                    controlBarHidden={settings.controlBarHidden}
                    colorPlayButton={settings.colorPlayButton}
                    colorControlBar={settings.colorControlBar}
                  />
                </PlayerWrapper>
              </section>
            </div>
          </CCardBody>
        </CCard>
      </ContentContainer>
    </>
  );
};

export default VideoDetail;
