import React from 'react';
import { CRow, CCol, CAlert } from '@coreui/react';

import LabeledSwitch from './LabeledSwitch';

const PlayerControlsSettings = ({
  playButtonHidden,
  controlBarHidden,
  onChange,
}) => {
  return (
    <div>
      <CRow>
        <CCol md="4">
          <LabeledSwitch
            label="play button"
            checked={!playButtonHidden}
            onChange={() => onChange({ playButtonHidden: !playButtonHidden })}
          />
        </CCol>
        <CCol md="4">
          <LabeledSwitch
            label="control bar"
            checked={!controlBarHidden}
            onChange={() => onChange({ controlBarHidden: !controlBarHidden })}
          />
        </CCol>
      </CRow>
      <CAlert
        className="mt-2"
        color="info"
        hidden={!(playButtonHidden && controlBarHidden)}
      >
        When the player controls are hidden, a video must be in the autoplay
        mode.
      </CAlert>
    </div>
  );
};

export default PlayerControlsSettings;
