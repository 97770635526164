import React from 'react';
import ClipboardJS from 'clipboard';
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CButton,
  CLink,
} from '@coreui/react';

import { OverlayDispatch } from '../contexts/overlay';

import { OVERLAY_CLOSED } from '../actions/overlay';

const VideoLinkOverlay = ({ videoUrl }) => {
  const overlayDispatch = React.useContext(OverlayDispatch);
  const [isCopied, setIsCopied] = React.useState(false);

  const close = () => {
    overlayDispatch({ type: OVERLAY_CLOSED });
  };

  React.useEffect(() => {
    const clipboard = new ClipboardJS('#copy-video-link');
    clipboard.on('success', () => {
      setIsCopied(true);
    });

    return () => {
      clipboard.destroy();
    };
  }, []);

  return (
    <CModal show={true} onClose={close}>
      <CModalHeader closeButton>
        <CModalTitle>Share the video</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="p-2 mb-3 border">
          <CLink id="video-link" href={videoUrl} target="_blank">
            {videoUrl}
          </CLink>
        </div>

        <div className="d-flex align-items-center">
          <CButton
            className="flex-grow-1"
            id="copy-video-link"
            data-clipboard-target="#video-link"
            color="primary"
            block
          >
            Copy
          </CButton>
          <div className="ml-3 font-xl" hidden={!isCopied}>
            ✅
          </div>
        </div>
      </CModalBody>
    </CModal>
  );
};

export default VideoLinkOverlay;
