import React from 'react';
import { CAlert, CLink } from '@coreui/react';

const VerifyEmailAlert = ({ initialShow }) => {
  const [show, setShow] = React.useState(initialShow);

  return (
    <CAlert
      color="warning"
      className="verify-email-alert"
      show={show}
      onShowChange={setShow}
      closeButton
    >
      Please{' '}
      <CLink to="/account/profile" className="alert-link">
        verify your e-mail
      </CLink>
    </CAlert>
  );
};

export default VerifyEmailAlert;
