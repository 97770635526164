import * as actions from '../actions/overlay';

export default function reducer(state, action) {
  switch (action.type) {
    case actions.OVERLAY_OPENED:
      action.overlay.props = action.props;
      return action.overlay;

    case actions.OVERLAY_CLOSED:
      return null;

    default:
      throw new Error(`Unknown overlay action: ${action.type}`);
  }
}
