import React from 'react';
import { useRouter } from 'next/router';

const Redirect = ({ to, external = false, delay = 0 }) => {
  const router = useRouter();

  React.useEffect(() => {
    let redirect;

    if (external) {
      redirect = () => window.location.assign(to);
    } else {
      redirect = () => router.push(to);
    }

    setTimeout(redirect, delay);
  });

  return null;
};

export default Redirect;
