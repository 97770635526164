import { usePlanSubscription } from '../hooks';

const Subscription = ({ status, children }) => {
  const { planSubscription } = usePlanSubscription();

  if (planSubscription && status.includes(planSubscription.status)) {
    return children;
  }

  return null;
};

export default Subscription;
