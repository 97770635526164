import React from 'react';
import firebase from 'firebase/app';
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

import UpdatePlanSubscriptionOverlay from './UpdatePlanSubscriptionOverlay';
import Subscription from './Subscription';
import UsageIndicator from './UsageIndicator';

import * as subscription from '../constants/subscription';

import { SIDEBAR_SET } from '../actions/sidebar';
import { OVERLAY_OPENED } from '../actions/overlay';

import { Sidebar, SidebarDispatch } from '../contexts/sidebar';
import { OverlayDispatch } from '../contexts/overlay';

const Header = () => {
  const sidebarShow = React.useContext(Sidebar);
  const sidebarDispatch = React.useContext(SidebarDispatch);
  const overlayDispatch = React.useContext(OverlayDispatch);

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow)
      ? false
      : 'responsive';
    sidebarDispatch({ type: SIDEBAR_SET, value: val });
  };

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow)
      ? true
      : 'responsive';
    sidebarDispatch({ type: SIDEBAR_SET, value: val });
  };

  const openUpdatePlanSubscriptionOverlay = () => {
    overlayDispatch({
      type: OVERLAY_OPENED,
      overlay: UpdatePlanSubscriptionOverlay,
    });
  };

  const signOut = async () => {
    await firebase.auth().signOut();
  };

  return (
    <CHeader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />

      <CHeaderBrand className="ml-3 mr-auto ml-md-5 d-lg-none" to="/">
        <CIcon name="logo" height="20" alt="Logo" />
      </CHeaderBrand>

      <div className="px-3 ml-auto d-flex align-items-center">
        <Subscription status={[subscription.ACTIVE]}>
          <UsageIndicator />
        </Subscription>
      </div>

      <CHeaderNav className="">
        {/* <Subscription status={[subscription.ACTIVE]}>
          <CHeaderNavItem className="px-3">
            <CButton
              color="primary"
              variant="outline"
              onClick={openUpdatePlanSubscriptionOverlay}
            >
              Upgrade
            </CButton>
          </CHeaderNavItem>
        </Subscription> */}
        <CHeaderNavItem className="px-3">
          <CButton onClick={signOut}>Sign Out</CButton>
        </CHeaderNavItem>
      </CHeaderNav>
    </CHeader>
  );
};

export default Header;
