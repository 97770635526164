import React from 'react';
import { CContainer, CAlert } from '@coreui/react';

import Sidebar from './Sidebar';
import Header from './Header';
import VerifyEmailAlert from './VerifyEmailAlert';
import Notifications from './Notifications';
import Subscription from '../components/Subscription';

import overlayReducer from '../reducers/overlay';
import sidebarReducer from '../reducers/sidebar';
import notificationsReducer from '../reducers/notifications';

import * as subscription from '../constants/subscription';

import { OverlayDispatch } from '../contexts/overlay';
import { NotificationDispatch } from '../contexts/notifications';
import {
  Sidebar as SidebarContext,
  SidebarDispatch,
} from '../contexts/sidebar';
import { User } from '../contexts/user';
import ContentContainer from './ContentContainer';

const defaultOverlay = null;

const Layout = ({ children }) => {
  const user = React.useContext(User);

  const [sidebarShow, sidebarShowDispatch] = React.useReducer(
    sidebarReducer,
    'responsive'
  );
  const [activeOverlay, overlayDispatch] = React.useReducer(
    overlayReducer,
    defaultOverlay
  );
  const [notifications, notificationDispatch] = React.useReducer(
    notificationsReducer,
    []
  );
  const isOverlayDisplayed = activeOverlay !== null;

  return (
    <OverlayDispatch.Provider value={overlayDispatch}>
      <SidebarDispatch.Provider value={sidebarShowDispatch}>
        <NotificationDispatch.Provider value={notificationDispatch}>
          <SidebarContext.Provider value={sidebarShow}>
            {isOverlayDisplayed &&
              React.createElement(activeOverlay, activeOverlay.props)}
            <VerifyEmailAlert initialShow={!user.emailVerified} />
            <Notifications items={notifications} />
            <div className="c-app">
              <Sidebar />
              <div className="c-wrapper">
                <Header />
                <div className="c-body">
                  <main className="c-main">
                    <CContainer fluid>
                      <ContentContainer>
                        <Subscription status={[subscription.PAST_DUE]}>
                          <CAlert color="warning">
                            Your subscription is not active. Functionality is
                            restricted. Please update your payment method.
                          </CAlert>
                        </Subscription>
                        <Subscription status={[subscription.CANCELED]}>
                          <CAlert color="warning">
                            Your subscription is not active. Functionality is
                            restricted. Please subscribe again.
                          </CAlert>
                        </Subscription>
                      </ContentContainer>
                      {children}
                    </CContainer>
                  </main>
                </div>
                {/* <TheFooter /> */}
              </div>
            </div>
          </SidebarContext.Provider>
        </NotificationDispatch.Provider>
      </SidebarDispatch.Provider>
    </OverlayDispatch.Provider>
  );
};

export default Layout;
