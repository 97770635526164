export const INCOMPLETE = 'incomplete';

export const INCOMPLETE_EXPIRED = 'incomplete_expired';

export const TRIALING = 'trialing';

export const ACTIVE = 'active';

export const PAST_DUE = 'past_due';

export const UNPAID = 'unpaid';

export const CANCELED = 'canceled';
