import React from 'react';
import { CRow, CCol, CButton } from '@coreui/react';

const PlayerThumbnailSettings = ({ onChange, onReset }) => {
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
    onChange(e.target.files[0]);
  };

  const handleReset = () => {
    setInputValue('');
    onReset();
  };

  return (
    <div>
      <CRow>
        <CCol md="4">
          <div className="d-inline-block">
            <label
              className="m-0 btn btn-pill btn-outline-secondary"
              htmlFor="thumbnail"
            >
              Change thumbnail
            </label>
            <input
              id="thumbnail"
              className="sr-only"
              name="thumbnail"
              type="file"
              accept="image/*"
              value={inputValue}
              onChange={handleChange}
            />
          </div>
        </CCol>
        <CCol md="4">
          <CButton onClick={handleReset}>Reset</CButton>
        </CCol>
        <CCol md="4">
          {inputValue && (
            <div className="d-flex align-items-center h-100">
              {inputValue.replace(/.+(\/|\\)/, '')}
            </div>
          )}
        </CCol>
      </CRow>
    </div>
  );
};

export default PlayerThumbnailSettings;
