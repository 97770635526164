import React from 'react';
import { CToaster, CToast, CToastHeader, CToastBody } from '@coreui/react';

import { NotificationDispatch } from '../contexts/notifications';

import { NOTIFICATION_HIDDEN } from '../actions/notifications';

const NOTIFICATION_TIMEOUT = 5000;

const Notifications = ({ items }) => {
  const notificationDispatch = React.useContext(NotificationDispatch);

  const handleHiddenNotification = (id, state) => {
    if (state === false) {
      notificationDispatch({ type: NOTIFICATION_HIDDEN, id });
    }
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <CToaster position="top-right">
      {items.map((item) => (
        <CToast
          key={item.id}
          autohide={NOTIFICATION_TIMEOUT}
          fade
          show
          onStateChange={(state) => handleHiddenNotification(item.id, state)}
        >
          <CToastHeader closeButton>Notification</CToastHeader>
          <CToastBody>{item.message}</CToastBody>
        </CToast>
      ))}
    </CToaster>
  );
};

export default Notifications;
