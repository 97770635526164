import React from 'react';
import PlayerControls from '@viktorfx/video-player-controls-ui';

const PlayerPreview = ({
  videoWidth,
  videoHeight,
  thumbnailUrl,
  playButtonHidden,
  controlBarHidden,
  colorPlayButton,
  colorControlBar,
}) => {
  return (
    <div
      className="player-preview"
      style={{
        paddingTop: `${(videoHeight / videoWidth) * 100}%`,
        backgroundImage: `url(${thumbnailUrl})`,
      }}
    >
      <PlayerControls
        playButtonHidden={playButtonHidden}
        controlBarHidden={controlBarHidden}
        colorPlayButton={colorPlayButton}
        colorControlBar={colorControlBar}
      />
    </div>
  );
};

export default PlayerPreview;
