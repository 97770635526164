export const getDisplayName = function (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export const isEqualObject = (actual, expected) => {
  const keys = Object.keys(expected);

  for (const k of keys) {
    if (actual[k] !== expected[k]) {
      return false;
    }
  }

  return true;
};
