import React from 'react';
import { PlayerControls } from '@viktorfx/video-player-controls';
import PlayerControlsUI from '@viktorfx/video-player-controls-ui';

import '@viktorfx/video-player-controls-ui/dist/style.css';

const Player = ({ src, videoWidth, videoHeight }) => {
  const playerApiRef = React.useRef(null);

  React.useEffect(() => {
    if (playerApiRef.current) {
      new PlayerControls(playerApiRef.current);
    }
  }, []);

  return (
    <div className="player">
      <div className="player-container">
        <div
          style={{
            margin: '0 auto',
            maxWidth: `calc((${videoWidth} / ${videoHeight}) * 100vh)`,
          }}
        >
          <video src={src} controls={false} ref={playerApiRef} />
        </div>
        <PlayerControlsUI
          playButtonHidden={false}
          controlBarHidden={false}
          colorPlayButton="#fff"
          colorControlBar="#fff"
          hidden
        />
      </div>
    </div>
  );
};

export default Player;
