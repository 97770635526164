import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';

import FormFieldErrorMessage from './FormFieldErrorMessage';

const FormField = ({ name, children }) => {
  return (
    <>
      <Field name={name}>
        {({ field, meta }) =>
          React.cloneElement(React.Children.only(children), {
            ...field,
            className: classNames(children.props.className, {
              'is-invalid': !!(meta.touched && meta.error),
            }),
          })
        }
      </Field>
      <FormFieldErrorMessage name={name} />
    </>
  );
};

export default FormField;
