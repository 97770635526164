import React from 'react';
import { CRow, CCol, CAlert } from '@coreui/react';
import CIcon from '@coreui/icons-react';

import LabeledSwitch from './LabeledSwitch';

const PlayerPlaybackSettings = ({
  autoplay,
  muted,
  loop,
  autoplayDisabled,
  onChange,
}) => {
  return (
    <div>
      <CRow>
        <CCol md="4">
          <LabeledSwitch
            label="autoplay"
            icon={<CIcon name="cil-media-play" />}
            checked={autoplay}
            disabled={autoplayDisabled}
            onChange={() => onChange({ autoplay: !autoplay })}
          />
        </CCol>
        <CCol md="4">
          <LabeledSwitch
            label="muted"
            icon={<CIcon name="cil-volume-off" />}
            checked={muted}
            disabled={autoplay}
            onChange={() => onChange({ muted: !muted })}
          />
        </CCol>
        <CCol md="4">
          <LabeledSwitch
            label="loop"
            icon={<CIcon name="cil-loop" />}
            checked={loop}
            onChange={() => onChange({ loop: !loop })}
          />
        </CCol>
      </CRow>
      <CAlert className="mt-2" color="info" hidden={!autoplay}>
        When the autoplay is set, a video must be muted to prevent playback
        issues.
      </CAlert>
    </div>
  );
};

export default PlayerPlaybackSettings;
