import { nanoid } from 'nanoid';

import * as actions from '../actions/notifications';

export default function reducer(state, action) {
  switch (action.type) {
    case actions.NOTIFICATION_CREATED:
      return [...state, { ...action.notification, id: nanoid() }];

    case actions.NOTIFICATION_HIDDEN:
      return state.filter((item) => item.id !== action.id);

    default:
      throw new Error(`Unknown notifications action: ${action.type}`);
  }
}
