import React from 'react';
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CButton,
  CRow,
  CCol,
  CSpinner,
} from '@coreui/react';
import { useHistory } from 'react-router-dom';

import { OverlayDispatch } from '../contexts/overlay';
import { NotificationDispatch } from '../contexts/notifications';

import { OVERLAY_CLOSED } from '../actions/overlay';
import { NOTIFICATION_CREATED } from '../actions/notifications';

import { deleteVideo } from '../services/video';

import { useGtag } from '../hooks';

const DeleteVideoOverlay = ({ videoId }) => {
  const overlayDispatch = React.useContext(OverlayDispatch);
  const notificationDispatch = React.useContext(NotificationDispatch);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const history = useHistory();
  const gtag = useGtag();

  const close = () => {
    overlayDispatch({ type: OVERLAY_CLOSED });
  };

  const handleVideoDeletion = async () => {
    setIsProcessing(true);

    await deleteVideo({ videoId });

    history.push('/library');
    notificationDispatch({
      type: NOTIFICATION_CREATED,
      notification: {
        message: 'Video was successfully deleted.',
      },
    });
    close();

    gtag('event', 'video_deleted');
  };

  return (
    <CModal show={true} onClose={close}>
      <CModalHeader closeButton>
        <CModalTitle>Confirm video deletion</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p>
          By deleting the video you will lose all the data associated with this
          video and the video link as well as embedded player will stop working.
        </p>
        <CRow>
          <CCol>
            <CButton
              color="danger"
              disabled={isProcessing}
              block
              onClick={handleVideoDeletion}
            >
              Delete <CSpinner size="sm" hidden={!isProcessing} />
            </CButton>
          </CCol>
          <CCol>
            <CButton block onClick={close}>
              Cancel
            </CButton>
          </CCol>
        </CRow>
      </CModalBody>
    </CModal>
  );
};

export default DeleteVideoOverlay;
