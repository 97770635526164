import React from 'react';

import Redirect from './Redirect';
import Loader from './Loader';

import { User } from '../contexts/user';

const AuthOnly = ({ render }) => {
  const user = React.useContext(User);

  return user ? (
    render()
  ) : user === null ? (
    <Redirect to="/signin" />
  ) : (
    <Loader />
  );
};

export default AuthOnly;
