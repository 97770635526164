import React from 'react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { Dashboard, useUppy } from '@uppy/react';
import { CAlert } from '@coreui/react';

import {
  ABORT_MULTIPART_UPLOAD,
  COMPLETE_MULTIPART_UPLOAD,
  CREATE_MULTIPART_UPLOAD,
  LIST_PARTS,
  PREPARE_UPLOAD_PARTS,
} from '../constants/videoUpload';
import * as subscription from '../constants/subscription';

import ContentContainer from './ContentContainer';
import Subscription from './Subscription';

import { useGtag } from '../hooks';

import { NotificationDispatch } from '../contexts/notifications';

import { NOTIFICATION_CREATED } from '../actions/notifications';

import { uploadVideo } from '../services/video';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

const AwsS3MultipartOptions = {
  limit: 5,
  async createMultipartUpload(file) {
    const event = {
      action: CREATE_MULTIPART_UPLOAD,
      key: file.name,
    };

    const { uploadId, key } = await uploadVideo({ event });

    return { uploadId, key };
  },
  async listParts(file, { uploadId, key }) {
    const event = {
      action: LIST_PARTS,
      uploadId,
      key,
    };

    const parts = await uploadVideo({ event });

    return parts;
  },
  async prepareUploadParts(file, { uploadId, key, partNumbers }) {
    const event = {
      action: PREPARE_UPLOAD_PARTS,
      uploadId,
      key,
      partNumbers,
    };

    const { presignedUrls } = await uploadVideo({ event });

    return { presignedUrls };
  },
  async abortMultipartUpload(file, { uploadId, key }) {
    const event = {
      action: ABORT_MULTIPART_UPLOAD,
      uploadId,
      key,
    };

    await uploadVideo({ event });
  },
  async completeMultipartUpload(file, { uploadId, key, parts }) {
    const event = {
      action: COMPLETE_MULTIPART_UPLOAD,
      uploadId,
      key,
      parts,
    };

    const { location } = await uploadVideo({ event });

    return { location };
  },
};

const VideoUpload = () => {
  const uppy = useUppy(() => {
    return new Uppy().use(AwsS3Multipart, AwsS3MultipartOptions);
  });
  const notificationDispatch = React.useContext(NotificationDispatch);
  const gtag = useGtag();

  React.useEffect(() => {
    const handleUploadSuccess = (file) => {
      notificationDispatch({
        type: NOTIFICATION_CREATED,
        notification: {
          message: `${file.name} successfully uploaded! It's being processed right now, will be ready in a sec.`,
        },
      });

      gtag('event', 'video_uploaded');
    };

    uppy.on('upload-success', handleUploadSuccess);

    return () => {
      uppy.off('upload-success', handleUploadSuccess);
    };
  }, [uppy, notificationDispatch, gtag]);

  return (
    <ContentContainer>
      <Subscription status={[subscription.ACTIVE]}>
        <Dashboard
          uppy={uppy}
          hidePauseResumeButton
          proudlyDisplayPoweredByUppy={false}
        />
      </Subscription>
      <Subscription status={[subscription.PAST_DUE, subscription.CANCELED]}>
        <CAlert color="danger">
          Video upload is currently disabled due to inactive subscription.
        </CAlert>
      </Subscription>
    </ContentContainer>
  );
};

export default VideoUpload;
