import React from 'react';
import {
  CButton,
  CCol,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react';

import CancelPlanSubscriptionForm from './CancelPlanSubscriptionForm';

import { OVERLAY_CLOSED } from '../actions/overlay';

import { OverlayDispatch } from '../contexts/overlay';

const CancelPlanSubscriptionOverlay = () => {
  const overlayDispatch = React.useContext(OverlayDispatch);

  const close = () => {
    overlayDispatch({ type: OVERLAY_CLOSED });
  };

  return (
    <CModal show={true} onClose={close}>
      <CModalHeader closeButton>
        <CModalTitle>Confirm cancelation</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CancelPlanSubscriptionForm />
        <CRow alignHorizontal="center">
          <CCol xs="12">
            <CButton
              color="primary"
              variant="outline"
              className="mt-2 d-block w-100"
              onClick={close}
            >
              No
            </CButton>
          </CCol>
        </CRow>
      </CModalBody>
    </CModal>
  );
};

export default CancelPlanSubscriptionOverlay;
