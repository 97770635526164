import firebase from 'firebase/app';
import { nanoid } from 'nanoid';

export const uploadVideo = async ({ event }) => {
  const res = await firebase.functions().httpsCallable('uploadVideo')({
    event,
  });

  return res.data;
};

export const uploadThumbnail = async ({ uid, videoId, file }) => {
  const ext = file.name.slice(file.name.lastIndexOf('.'));

  // generate unique filename and remove forbidden characters
  const key = `storage_${nanoid().replace(/#|\[|\]|\*|\?/gi, '')}${ext}`;

  await firebase
    .storage()
    .ref(`images/${uid}/thumbnails/${videoId}/${key}`)
    .put(file);

  return key;
};

export const saveVideoSettings = async ({ videoId, settings }) => {
  const res = await firebase.functions().httpsCallable('saveVideoSettings')({
    videoId,
    settings,
  });

  return res.data;
};

export const getVideoInfo = async ({ videoId, uid }) => {
  const videoInfo = await firebase
    .database()
    .ref(`videos/${uid}/${videoId}`)
    .get()
    .then((snapshot) => snapshot.val());

  return videoInfo;
};

export const getVideoSettings = async ({ videoId }) => {
  const videoSettings = await firebase
    .database()
    .ref(`videoSettings/${videoId}`)
    .get()
    .then((snapshot) => snapshot.val());

  return videoSettings;
};

export const deleteVideo = async ({ videoId }) => {
  const res = await firebase.functions().httpsCallable('deleteVideo')({
    videoId,
  });

  return res.data;
};
