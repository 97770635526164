import React from 'react';
import classNames from 'classnames';
import { CCol, CRow } from '@coreui/react';

import * as billingTypes from '../constants/billing';

import Plan from './Plan';

const SelectPlan = ({ plans, onChange, defaultPlan = null }) => {
  const [billing, setBilling] = React.useState(
    defaultPlan?.price.recurring.interval || billingTypes.MONTH
  );
  const [priceId, setPriceId] = React.useState(defaultPlan?.price.id);

  React.useEffect(() => {
    // hook always runs after mount
    if (priceId) {
      onChange(priceId);
    }
  }, [onChange, priceId]);

  const billingBtnClassName =
    'd-inline-block px-4 py-2 font-lg user-select-none cursor-pointer';

  return (
    <div>
      {/* <CRow alignHorizontal="center">
        <CCol xs="auto">
          <div className="mb-4 overflow-hidden border rounded-pill">
            <span
              className={classNames(billingBtnClassName, {
                'bg-secondary': billing === billingTypes.MONTH,
                'text-white': billing === billingTypes.MONTH,
              })}
              onClick={() => setBilling(billingTypes.MONTH)}
            >
              Monthly
            </span>
            <span
              className={classNames(billingBtnClassName, {
                'bg-secondary': billing === billingTypes.YEAR,
                'text-white': billing === billingTypes.YEAR,
              })}
              onClick={() => setBilling(billingTypes.YEAR)}
            >
              Annually
            </span>
          </div>
        </CCol>
      </CRow> */}
      <CRow>
        {plans
          .filter((p) => p.price.recurring.interval === billing)
          .map((p, i) => (
            <CCol key={`plan-${i}`} xs="12" lg="3" className="py-2">
              <Plan
                name={p.name}
                limit={p.limit}
                price={p.price.unit_amount / 100}
                period={billing === billingTypes.MONTH ? 'month' : 'year'}
                selected={p.price.id === priceId}
                onClick={() => setPriceId(p.price.id)}
              />
            </CCol>
          ))}
      </CRow>
    </div>
  );
};

export default SelectPlan;
