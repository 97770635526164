import React from 'react';
import { CRow, CCol } from '@coreui/react';

import ColorInputField from './ColorInputField';

const PlayerColorsSettings = ({
  colorPlayButton,
  colorControlBar,
  onChange,
}) => {
  return (
    <div>
      <CRow>
        <CCol md="4">
          <ColorInputField
            label="play button"
            initialColor={colorPlayButton}
            onChange={(color) => onChange({ colorPlayButton: color })}
          />
        </CCol>
        <CCol md="4">
          <div>
            <ColorInputField
              label="control bar"
              initialColor={colorControlBar}
              onChange={(color) => onChange({ colorControlBar: color })}
            />
          </div>
        </CCol>
      </CRow>
    </div>
  );
};

export default PlayerColorsSettings;
