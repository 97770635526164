import React from 'react';
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  CSidebarNavItem,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

import { SIDEBAR_SET } from '../actions/sidebar';

import {
  Sidebar as SidebarContext,
  SidebarDispatch,
} from '../contexts/sidebar';

const Sidebar = () => {
  const sidebarShow = React.useContext(SidebarContext);
  const sidebarDispatch = React.useContext(SidebarDispatch);

  return (
    <CSidebar
      show={sidebarShow}
      onShowChange={(val) =>
        sidebarDispatch({
          type: SIDEBAR_SET,
          value: val,
        })
      }
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <CIcon className="c-sidebar-brand-full" name="logo" height={20} />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="logo-letter"
          height={20}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavItem
          name="Upload video"
          to="/upload-video"
          icon={
            <CIcon name="cil-cloud-upload" customClasses="c-sidebar-nav-icon" />
          }
        />
        <CSidebarNavItem
          name="Library"
          to="/library"
          icon={<CIcon name="cil-movie" customClasses="c-sidebar-nav-icon" />}
          exact={false}
        />
        <CSidebarNavItem
          name="Help"
          href="https://wolvido.com/help"
          target="_blank"
          icon={<CIcon name="help" customClasses="c-sidebar-nav-icon" />}
          className="mt-auto"
        />
        <CSidebarNavItem
          name="Account"
          to="/account"
          icon={<CIcon name="cil-user" customClasses="c-sidebar-nav-icon" />}
          exact={false}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default Sidebar;
