import React from 'react';
import firebase from 'firebase/app';
import {
  CCard,
  CCardBody,
  CForm,
  CFormGroup,
  CLabel,
  CBadge,
  CAlert,
  CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

import ChangePasswordForm from './ChangePasswordForm';

import { User } from '../contexts/user';

const UserProfile = () => {
  const user = React.useContext(User);
  const [verificationEmailSent, setVerificationEmailSent] =
    React.useState(false);

  const hasEmailLogin = user.providerData.some(
    (data) => data.providerId === firebase.auth.EmailAuthProvider.PROVIDER_ID
  );

  const sendVerificationEmail = async () => {
    await user.sendEmailVerification({
      url: window.location.origin,
    });
    setVerificationEmailSent(true);
  };

  return (
    <div>
      <CCard>
        <CCardBody>
          <CForm>
            <CFormGroup>
              <CLabel>Name</CLabel>
              <p className="form-control-static">{user.displayName}</p>
            </CFormGroup>
            <CFormGroup>
              <CLabel>E-mail</CLabel>
              <p className="form-control-static">
                <span className="align-middle">{user.email}</span>{' '}
                {user.emailVerified ? (
                  <CBadge color="success">
                    <CIcon name="cil-check-alt" />
                    <span className="sr-only">E-mail verified</span>
                  </CBadge>
                ) : (
                  <CBadge color="danger">
                    <CIcon name="cil-x" />
                    <span className="sr-only">E-mail not verified</span>
                  </CBadge>
                )}
              </p>
              {!user.emailVerified ? (
                verificationEmailSent ? (
                  <CAlert color="success">Verification email sent.</CAlert>
                ) : (
                  <CAlert color="danger">
                    Your e-mail is not verified. If you didn&apos;t receive the
                    verification email, click{' '}
                    <CLink
                      className="alert-link"
                      onClick={sendVerificationEmail}
                    >
                      here
                    </CLink>{' '}
                    to resend it.
                  </CAlert>
                )
              ) : null}
            </CFormGroup>
          </CForm>
        </CCardBody>
      </CCard>
      {hasEmailLogin ? (
        <div>
          <h3>Password change</h3>
          <CCard>
            <CCardBody>
              <ChangePasswordForm />
            </CCardBody>
          </CCard>
        </div>
      ) : null}
    </div>
  );
};

export default UserProfile;
