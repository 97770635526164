import React from 'react';
import { CRow, CCol, CProgress, CSpinner } from '@coreui/react';
import { Duration } from 'luxon';

import { useCurrentPlan, usePlanUsage } from '../hooks';

const toPercent = (num) => num * 100;

const UsageIndicator = () => {
  const currentPlan = useCurrentPlan();
  const usage = usePlanUsage();

  if (!currentPlan || !usage) {
    return <CSpinner />;
  }

  let { hours, minutes } = Duration.fromObject({
    seconds: currentPlan.limit - usage.current,
  }).shiftTo('hours', 'minutes');
  minutes = Math.floor(minutes);

  return (
    <div>
      <CRow>
        <CCol xs="auto">
          <span>Usage</span>
        </CCol>
        <CCol>
          <span className="font-sm text-muted">
            {hours === 0 && minutes === 0 ? 'no minutes' : ''}
            {hours > 0
              ? `${hours} ${
                  hours.toString().slice(-1) === '1' ? 'hour' : 'hours'
                }`
              : ''}{' '}
            {minutes > 0
              ? `${minutes} ${
                  minutes.toString().slice(-1) === '1' ? 'minute' : 'minutes'
                }`
              : ''}{' '}
            remaining
          </span>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CProgress
            value={toPercent(usage.current / currentPlan.limit)}
            color="primary"
            size="xs"
            className="mt-1"
          />
        </CCol>
      </CRow>
    </div>
  );
};

export default UsageIndicator;
