import React from 'react';
import {
  HashRouter,
  StaticRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { CFade } from '@coreui/react';
import Head from 'next/head';

import Layout from '../components/Layout';
import VideoUpload from '../components/VideoUpload';
import Library from '../components/Library';
import Account from '../components/Account';
import AuthOnly from '../components/AuthOnly';
import PageRedirect from '../components/Redirect';
import Subscription from '../components/Subscription';
import VideoDetail from '../components/VideoDetail';
import PageViewTracker from '../components/PageViewTracker';

import * as subscription from '../constants/subscription';

import { usePlanSubscription } from '../hooks';

const Router = typeof window !== 'undefined' ? HashRouter : StaticRouter;

const AppPage = ({ children, dontTrack, title }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      {dontTrack ? null : <PageViewTracker key={title} />}
      <AuthOnly
        render={() => (
          <Subscription
            status={[
              subscription.ACTIVE,
              subscription.PAST_DUE,
              subscription.CANCELED,
            ]}
          >
            <Layout>{children}</Layout>
          </Subscription>
        )}
      />
    </>
  );
};

const IndexPage = () => {
  const { planSubscription, isValidatingPlanSubscription } =
    usePlanSubscription();

  if (
    !isValidatingPlanSubscription &&
    (planSubscription === null ||
      planSubscription?.status === subscription.INCOMPLETE ||
      planSubscription?.status === subscription.INCOMPLETE_EXPIRED)
  ) {
    return <PageRedirect to={`/subscribe${window.location.search}`} />;
  }

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/upload-video"
          render={(props) => (
            <AppPage {...props} title="Upload video | Wolvido">
              <CFade key="f-upload-video">
                <VideoUpload />
              </CFade>
            </AppPage>
          )}
        />
        <Route
          path="/library/video/:id"
          render={(props) => (
            <AppPage {...props} title="" dontTrack>
              <CFade key="f-library-video">
                <VideoDetail id={props.match.params.id} />
              </CFade>
            </AppPage>
          )}
        />
        <Route
          exact
          path="/library"
          render={(props) => (
            <AppPage {...props} title="Library | Wolvido">
              <CFade key="f-library">
                <Library />
              </CFade>
            </AppPage>
          )}
        />
        <Route
          path="/account"
          render={(props) => (
            <AppPage {...props} title="Account | Wolvido" dontTrack>
              <CFade key="f-account">
                <Account />
              </CFade>
            </AppPage>
          )}
        />
        <Redirect from="/" to="/library" />
      </Switch>
    </Router>
  );
};

export default IndexPage;
