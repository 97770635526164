import React from 'react';
import { CDropdown, CDropdownToggle, CDropdownMenu } from '@coreui/react';
import { HexColorPicker, HexColorInput } from 'react-colorful';

const ColorInputField = ({ label, onChange, initialColor = '#000' }) => {
  const [color, setColor] = React.useState(initialColor);
  const handleColorChange = (newColor) => {
    setColor(newColor);
    onChange(newColor);
  };

  return (
    <div className="d-flex align-items-center">
      <CDropdown>
        <CDropdownToggle
          tag="div"
          caret={false}
          style={{
            border: '1px solid #d8dbe0',
            borderRadius: '50%',
            height: '20px',
            width: '20px',
            backgroundColor: color,
          }}
        />
        <CDropdownMenu className="py-0">
          <HexColorPicker color={color} onChange={handleColorChange} />
          <div className="m-3">
            <HexColorInput
              color={color}
              onChange={handleColorChange}
              prefixed
            />
          </div>
        </CDropdownMenu>
      </CDropdown>
      <div className="ml-2">{label}</div>
    </div>
  );
};

export default ColorInputField;
