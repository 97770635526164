import React from 'react';
import { Duration, DateTime } from 'luxon';
import { CBadge, CCard, CCardBody, CCol, CRow } from '@coreui/react';

import { VIDEO_PROCESSING } from '../constants/videoStatus';

const VideoOverview = ({
  name,
  status,
  uploadDate,
  duration = null,
  thumbnail = null,
}) => {
  const formattedDuration = Duration.fromObject({
    seconds: duration || 0,
  }).toFormat('h:mm:ss');

  const formattedUploadDate = DateTime.fromSeconds(uploadDate).toLocaleString(
    DateTime.DATE_MED
  );

  return (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol md="auto">
            <div
              className="d-inline-flex justify-content-center align-items-center position-relative border border-dark"
              style={{ width: '162px', height: '92px' }}
            >
              {thumbnail && (
                <img className="mw-100 mh-100" src={thumbnail} alt="" />
              )}
              {duration && (
                <div className="bottom-0 right-0 px-2 py-1 text-white bg-black font-weight-bold position-absolute">
                  {formattedDuration}
                </div>
              )}
            </div>
          </CCol>
          <CCol>
            <div className="">
              <span className="font-lg">{name}</span>{' '}
              {status === VIDEO_PROCESSING ? (
                <CBadge color="info" className="font-xs">
                  {status}
                </CBadge>
              ) : null}
            </div>
            <div className="text-muted">{formattedUploadDate}</div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default VideoOverview;
