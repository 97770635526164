import React from 'react';
import { CSpinner } from '@coreui/react';
import classNames from 'classnames';

const Loader = ({ asOverlay = false }) => {
  return (
    <div
      className={classNames(
        'd-flex justify-content-center align-items-center vh-100',
        {
          'fixed-top': asOverlay,
          'bg-black-50': asOverlay,
        }
      )}
    >
      <CSpinner color="secondary" style={{ width: '4rem', height: '4rem' }} />
    </div>
  );
};

export default Loader;
