import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CLink,
  CNav,
  CNavItem,
  CNavLink,
} from '@coreui/react';

import UserProfile from './UserProfile';
import Billing from './Billing';
import ContentContainer from './ContentContainer';
import PageViewTracker from './PageViewTracker';

const Account = () => {
  const [activePage, setActivePage] = React.useState('');
  let mobileMenuEl = null;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    const page =
      mobileMenuEl &&
      [...mobileMenuEl.childNodes]
        .filter((link) => link.classList.contains('active'))
        .reduce((acc, link) => link.textContent, '');

    if (activePage !== page) {
      setActivePage(page);
    }
  });

  return (
    <div>
      <CDropdown className="mb-4 d-lg-none">
        <CDropdownToggle color="secondary" size="lg" block>
          {activePage}
        </CDropdownToggle>
        <CDropdownMenu className="w-100" innerRef={(el) => (mobileMenuEl = el)}>
          <CDropdownItem
            className="justify-content-center"
            tag={CLink}
            to="/account/profile"
          >
            Profile
          </CDropdownItem>
          <CDropdownItem
            className="justify-content-center"
            tag={CLink}
            to="/account/billing"
          >
            Billing
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>

      <ContentContainer>
        <CNav className="mb-4 d-md-down-none" variant="pills" justified>
          <CNavItem>
            <CNavLink
              to="/account/profile"
              style={{ color: 'var(--secondary)' }}
              activeClassName="text-white bg-secondary"
            >
              Profile
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              to="/account/billing"
              style={{ color: 'var(--secondary)' }}
              activeClassName="text-white bg-secondary"
            >
              Billing
            </CNavLink>
          </CNavItem>
        </CNav>
      </ContentContainer>

      <ContentContainer>
        <Switch>
          <Route
            exact
            path="/account/profile"
            render={() => (
              <React.Fragment key="account-profile">
                <PageViewTracker />
                <UserProfile />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/account/billing"
            render={() => (
              <React.Fragment key="account-billing">
                <PageViewTracker />
                <Billing />
              </React.Fragment>
            )}
          />
          <Redirect from="/account" to="/account/profile" />
        </Switch>
      </ContentContainer>
    </div>
  );
};

export default Account;
