import React from 'react';
import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';

import UpdateCardForm from './UpdateCardForm';

import { OVERLAY_CLOSED } from '../actions/overlay';

import { OverlayDispatch } from '../contexts/overlay';

const UpdateCardOverlay = () => {
  const overlayDispatch = React.useContext(OverlayDispatch);

  const close = () => {
    overlayDispatch({ type: OVERLAY_CLOSED });
  };

  return (
    <CModal show={true} onClose={close}>
      <CModalHeader closeButton>
        <CModalTitle>Enter card details</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <UpdateCardForm />
      </CModalBody>
    </CModal>
  );
};

export default UpdateCardOverlay;
