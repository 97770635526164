import React from 'react';
import firebase from 'firebase/app';
import { Formik } from 'formik';
import * as yup from 'yup';
import { CButton, CForm, CFormGroup, CInput, CLabel } from '@coreui/react';

import FormField from './FormField';
import withFormResult from './withFormResult';

import { User } from '../contexts/user';

import { MIN_PASSWORD_LENGTH } from '../settings';

const schema = yup.object().shape({
  current_password: yup.string().label('Current password').required(),
  new_password: yup
    .string()
    .label('New password')
    .required()
    .min(MIN_PASSWORD_LENGTH),
  new_password_2: yup
    .string()
    .label('New password')
    .required()
    .oneOf([yup.ref('new_password')], `New passwords don't match`),
});

const ChangePasswordForm = ({
  setSuccessResult,
  setErrorResult,
  resetResult,
}) => {
  const user = React.useContext(User);

  const changePassword = async (
    { current_password, new_password },
    actions
  ) => {
    resetResult();

    try {
      await user.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(user.email, current_password)
      );
      await user.updatePassword(new_password);

      setSuccessResult('Your password was successfully changed.');
      actions.resetForm();
    } catch (error) {
      let message = error.message;

      if (error.code === 'auth/wrong-password') {
        message = 'You entered incorrect current password.';
      }

      setErrorResult(message);
    }
  };

  return (
    <Formik
      initialValues={{
        current_password: '',
        new_password: '',
        new_password_2: '',
      }}
      validationSchema={schema}
      onSubmit={changePassword}
    >
      {({ isSubmitting, handleSubmit }) => (
        <CForm onSubmit={handleSubmit}>
          <CFormGroup>
            <CLabel htmlFor="current-password">Current password</CLabel>
            <FormField name="current_password">
              <CInput id="current-password" type="password" />
            </FormField>
          </CFormGroup>
          <CFormGroup>
            <CLabel htmlFor="new-password">New password</CLabel>
            <FormField name="new_password">
              <CInput id="new-password" type="password" />
            </FormField>
          </CFormGroup>
          <CFormGroup>
            <CLabel htmlFor="new-password-2">New password again</CLabel>
            <FormField name="new_password_2">
              <CInput id="new-password-2" type="password" />
            </FormField>
          </CFormGroup>
          <CButton color="primary" type="submit" disabled={isSubmitting}>
            Save password
          </CButton>
        </CForm>
      )}
    </Formik>
  );
};

export default withFormResult(ChangePasswordForm);
