import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import { CardElement } from '@stripe/react-stripe-js';

import FormFieldErrorMessage from './FormFieldErrorMessage';

// https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options
const CARD_OPTIONS = {
  // https://stripe.com/docs/js/appendix/style
  style: {
    base: {
      color: '#768192',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontSize: '14px',
      fontWeight: 400,
      fontSmoothing: 'antialiased',
    },
  },
};

const CardField = ({ name }) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(name);

  const { setError, setValue, setTouched } = helpers;

  const handleFocus = () => {
    setError('');
  };

  const handleBlur = () => {
    setTouched(true, !meta.error);
  };

  const handleChange = (e) => {
    if (!meta.touched) {
      setTouched(true, false);
    }

    if (e.error) {
      setError(e.error.message);
    } else {
      setError('');
    }

    setValue(e.complete, false);
  };

  return (
    <>
      <div
        className={classNames(
          'form-control',
          'd-flex',
          'flex-column',
          'justify-content-center',
          { 'is-invalid': !!(meta.touched && meta.error) }
        )}
      >
        <CardElement
          options={CARD_OPTIONS}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
      <FormFieldErrorMessage name={name} />
    </>
  );
};

export default CardField;
