import React from 'react';
import classNames from 'classnames';
import { CAlert } from '@coreui/react';

import { getDisplayName } from '../utils/component';

const FORM_SUCCESS = 'form-success';
const FORM_ERROR = 'form-error';

function withFormResult(WrappedComponent) {
  class WithFormResult extends React.Component {
    constructor(props) {
      super(props);

      this.setSuccessResult = this.setSuccessResult.bind(this);
      this.setErrorResult = this.setErrorResult.bind(this);
      this.resetResult = this.resetResult.bind(this);

      this.state = {
        result: null,
      };
    }

    setSuccessResult(message = 'Success') {
      this.setState({ result: { type: FORM_SUCCESS, message } });
      this.props.onSuccess();
    }

    setErrorResult(message = 'Error') {
      this.setState({ result: { type: FORM_ERROR, message } });
      this.props.onError();
    }

    resetResult() {
      this.setState({ result: null });
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { onSuccess, onError, ...passThroughProps } = this.props;

      return (
        <div>
          {this.state.result ? (
            <CAlert
              color={classNames({
                success: this.state.result.type === FORM_SUCCESS,
                danger: this.state.result.type === FORM_ERROR,
              })}
              closeButton
            >
              {this.state.result.message}
            </CAlert>
          ) : null}
          <WrappedComponent
            setSuccessResult={this.setSuccessResult}
            setErrorResult={this.setErrorResult}
            resetResult={this.resetResult}
            {...passThroughProps}
          />
        </div>
      );
    }
  }

  WithFormResult.displayName = `WithFormResult(${getDisplayName(
    WrappedComponent
  )})`;

  WithFormResult.defaultProps = {
    onSuccess: () => {},
    onError: () => {},
  };

  return WithFormResult;
}

export default withFormResult;
