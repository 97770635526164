import React from 'react';

import { useGtag } from '../hooks';

const PageViewTracker = () => {
  const gtag = useGtag();

  React.useEffect(() => {
    setTimeout(
      () => {
        gtag('event', 'page_view', {
          page_title: document.title,
          page_location: window.location.href,
        });
      },
      // timeout for CFade transition duration + Head updating page title
      200
    );
  }, [gtag]);

  return null;
};

export default PageViewTracker;
