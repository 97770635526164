import React from 'react';
import ClipboardJS from 'clipboard';
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CFade,
  CButton,
  CButtonGroup,
  CInput,
  CRow,
  CCol,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInputGroupAppend,
  CAlert,
} from '@coreui/react';

import { OverlayDispatch } from '../contexts/overlay';

import { OVERLAY_CLOSED } from '../actions/overlay';

const Responsive = ({ videoHeight, videoWidth, videoUrl, videoName }) => {
  return (
    <div>
      <CAlert color="info">
        This is the recommended option. The player will adjust its size
        according to used device.
      </CAlert>
      <div className="p-2 border">
        <code id="video-embed-code">
          {` <div style="padding:${
            (videoHeight / videoWidth) * 100
          }% 0 0 0;position:relative;"><iframe src="${videoUrl}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${videoName}"></iframe></div>`}
        </code>
      </div>
    </div>
  );
};

const Fixed = ({ videoUrl, videoName, initialWidth, initialHeight }) => {
  const [width, setWidth] = React.useState(initialWidth);
  const [height, setHeight] = React.useState(initialHeight);

  return (
    <div>
      <CRow>
        <CCol xs="12" md="6">
          <CInputGroup className="my-1">
            <CInputGroupPrepend>
              <CInputGroupText style={{ minWidth: '5em' }}>
                Width
              </CInputGroupText>
            </CInputGroupPrepend>
            <CInput value={width} onChange={(e) => setWidth(e.target.value)} />
            <CInputGroupAppend>
              <CInputGroupText>px</CInputGroupText>
            </CInputGroupAppend>
          </CInputGroup>
        </CCol>
        <CCol xs="12" md="6">
          <CInputGroup className="my-1">
            <CInputGroupPrepend>
              <CInputGroupText style={{ minWidth: '5em' }}>
                Height
              </CInputGroupText>
            </CInputGroupPrepend>
            <CInput
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
            <CInputGroupAppend>
              <CInputGroupText>px</CInputGroupText>
            </CInputGroupAppend>
          </CInputGroup>
        </CCol>
      </CRow>
      <div className="p-2 mt-3 border">
        <code id="video-embed-code">
          {`<iframe src="${videoUrl}" width="${width}" height="${height}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="${videoName}"></iframe>`}
        </code>
      </div>
    </div>
  );
};

const VideoEmbedCodeOverlay = ({
  videoWidth,
  videoHeight,
  videoName,
  videoUrl,
}) => {
  const overlayDispatch = React.useContext(OverlayDispatch);
  const [layout, setLayout] = React.useState('responsive');
  const [isCopied, setIsCopied] = React.useState(false);

  const close = () => {
    overlayDispatch({ type: OVERLAY_CLOSED });
  };

  React.useLayoutEffect(() => {
    setIsCopied(false);
  }, [layout]);

  React.useEffect(() => {
    const clipboard = new ClipboardJS('#copy-video-embed-code');
    clipboard.on('success', () => {
      setIsCopied(true);
    });

    return () => {
      clipboard.destroy();
    };
  }, []);

  return (
    <CModal show={true} onClose={close}>
      <CModalHeader closeButton>
        <CModalTitle>Embed the video in your site</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div>
          <CButtonGroup className="d-flex">
            <CButton
              color={`${layout === 'responsive' ? 'secondary' : ''}`}
              style={{ flexBasis: '130px', flexShrink: '0' }}
              onClick={() => setLayout('responsive')}
            >
              Responsive
            </CButton>
            <CButton
              color={`${layout === 'fixed' ? 'secondary' : ''}`}
              style={{ flexBasis: '130px', flexShrink: '0' }}
              onClick={() => setLayout('fixed')}
            >
              Fixed
            </CButton>
          </CButtonGroup>
        </div>

        <div className="my-4">
          {layout === 'responsive' ? (
            <CFade key="f-responsive">
              <Responsive
                videoWidth={videoWidth}
                videoHeight={videoHeight}
                videoName={videoName}
                videoUrl={videoUrl}
              />
            </CFade>
          ) : (
            <CFade key="f-fixed">
              <Fixed
                videoName={videoName}
                videoUrl={videoUrl}
                initialWidth={videoWidth}
                initialHeight={videoHeight}
              />
            </CFade>
          )}
        </div>

        <div className="d-flex align-items-center">
          <CButton
            className="flex-grow-1"
            id="copy-video-embed-code"
            data-clipboard-target="#video-embed-code"
            color="primary"
            block
          >
            Copy
          </CButton>
          <div className="ml-3 font-xl" hidden={!isCopied}>
            ✅
          </div>
        </div>
      </CModalBody>
    </CModal>
  );
};

export default VideoEmbedCodeOverlay;
