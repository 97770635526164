import React from 'react';
import { CSwitch } from '@coreui/react';

const LabeledSwitch = ({ label, icon = null, ...props }) => {
  return (
    <div className="d-inline-flex align-items-center">
      <CSwitch className="mr-2" color="primary" shape="pill" {...props} />
      {icon && <span className="mr-1">{icon}</span>}
      <span>{label}</span>
    </div>
  );
};

export default LabeledSwitch;
