import React from 'react';
import classNames from 'classnames';
import { Duration } from 'luxon';

const toMonthlyPrice = (price, period) =>
  period === 'year' ? price / 12 : price;

const Plan = ({
  name,
  price,
  limit,
  period,
  selected = false,
  ...otherProps
}) => {
  let { hours, minutes } = Duration.fromObject({
    seconds: limit,
  }).shiftTo('hours', 'minutes');
  minutes = Math.floor(minutes);

  return (
    <div
      className={classNames(
        'rounded',
        'text-center',
        'py-4',
        'cursor-pointer',
        'user-select-none',
        {
          'bg-light': !selected,
          'bg-primary': selected,
          'text-white': selected,
        }
      )}
      {...otherProps}
    >
      <span className="font-3xl">
        <strong>{name}</strong>
      </span>
      <br />
      <span className="font-lg" style={{ lineHeight: 2.5 }}>
        {hours > 0
          ? `${hours} ${hours.toString().slice(-1) === '1' ? 'hour' : 'hours'}`
          : ''}{' '}
        {minutes > 0
          ? `${minutes} ${
              minutes.toString().slice(-1) === '1' ? 'minute' : 'minutes'
            }`
          : ''}{' '}
        {/* / {period} */}
      </span>
      <br />
      <span className="font-xl">
        <strong>${toMonthlyPrice(price, period)}</strong>
      </span>{' '}
      <span className="font-lg">/ month</span>
    </div>
  );
};

export default Plan;
