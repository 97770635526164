import React from 'react';
import classNames from 'classnames';
import * as yup from 'yup';
import { Formik } from 'formik';
import { CForm, CButton, CCol, CRow, CSpinner } from '@coreui/react';

import SelectPlan from './SelectPlan';
import FormField from './FormField';
import withFormResult from './withFormResult';

import { usePlans, useCurrentPlan, usePlanSubscription } from '../hooks';

import { updatePlanSubscription } from '../services/planSubscription';

const schema = yup.object().shape({
  price_id: yup.string().required('Please select a plan'),
});

const UpdatePlanSubscriptionForm = ({
  setSuccessResult,
  setErrorResult,
  resetResult,
}) => {
  const { plans } = usePlans();
  const currentPlan = useCurrentPlan();
  const { refreshPlanSubscription } = usePlanSubscription();
  const [planPriceId, setPlanPriceId] = React.useState('');
  const dataLoaded = Boolean(plans && currentPlan);

  const updatePlan = async ({ price_id }) => {
    resetResult();

    if (price_id === currentPlan.priceId) {
      setErrorResult('You selected the current plan.');

      return;
    }

    try {
      await updatePlanSubscription({ priceId: price_id });

      refreshPlanSubscription();

      setSuccessResult('Plan was successfully updated.');
    } catch (error) {
      setErrorResult(
        'Apologies! Error occurred during the update, our team was notified.'
      );
    }
  };

  React.useEffect(() => {
    if (dataLoaded) {
      setPlanPriceId(currentPlan.priceId);
    }
  }, [dataLoaded, currentPlan?.priceId]);

  if (!dataLoaded) {
    return <CSpinner />;
  }

  return (
    <div>
      <SelectPlan
        plans={plans}
        defaultPlan={currentPlan}
        onChange={setPlanPriceId}
      />
      <Formik
        initialValues={{
          price_id: currentPlan.priceId,
        }}
        validationSchema={schema}
        onSubmit={updatePlan}
      >
        {({ isSubmitting, errors, values, setFieldValue, handleSubmit }) => {
          if (values.price_id !== planPriceId) {
            setFieldValue('price_id', planPriceId);
          }

          return (
            <CForm className="mt-4" onSubmit={handleSubmit}>
              <FormField name="price_id">
                <input
                  type="hidden"
                  className={classNames({ 'is-invalid': errors.price_id })}
                />
              </FormField>
              <CRow alignHorizontal="center">
                <CCol lg="3">
                  <CButton
                    type="submit"
                    variant="outline"
                    color="primary"
                    className="d-block w-100"
                    disabled={isSubmitting}
                  >
                    Update
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          );
        }}
      </Formik>
    </div>
  );
};

export default withFormResult(UpdatePlanSubscriptionForm);
