import React from 'react';
import { useRouter } from 'next/router';
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CAlert,
  CRow,
  CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { DateTime } from 'luxon';

import Plan from './Plan';
import Subscription from './Subscription';
import UpdatePlanSubscriptionOverlay from './UpdatePlanSubscriptionOverlay';
import CancelPlanSubscriptionOverlay from './CancelPlanSubscriptionOverlay';
import ResubscribeOverlay from './ResubscribeOverlay';
import UpdateCardOverlay from './UpdateCardOverlay';

import * as subscriptions from '../constants/subscription';

import { OVERLAY_OPENED } from '../actions/overlay';

import { usePlanSubscription, useCurrentPlan, usePlans } from '../hooks';

import { OverlayDispatch } from '../contexts/overlay';

const Billing = () => {
  const router = useRouter();
  const { planSubscription } = usePlanSubscription();
  const { plans } = usePlans();
  const currentPlan = useCurrentPlan();
  const overlayDispatch = React.useContext(OverlayDispatch);
  let followingPlan = null;

  const openUpdatePlanSubscriptionOverlay = () => {
    overlayDispatch({
      type: OVERLAY_OPENED,
      overlay: UpdatePlanSubscriptionOverlay,
    });
  };

  const openUpdateCardOverlay = () => {
    overlayDispatch({
      type: OVERLAY_OPENED,
      overlay: UpdateCardOverlay,
    });
  };

  const openCancelPlanSubscriptionOverlay = () => {
    overlayDispatch({
      type: OVERLAY_OPENED,
      overlay: CancelPlanSubscriptionOverlay,
    });
  };

  const openResubscribeOverlay = () => {
    overlayDispatch({
      type: OVERLAY_OPENED,
      overlay: ResubscribeOverlay,
    });
  };

  if (planSubscription?.schedule?.status === 'active') {
    const schedule = planSubscription.schedule;
    const currentPhaseIndex = schedule.phases.findIndex(
      (phase) => phase.start_date === schedule.current_phase.start_date
    );
    const followingPhase = schedule.phases[currentPhaseIndex + 1];

    if (followingPhase) {
      followingPlan = {
        ...plans?.find((p) => p.priceId === followingPhase.items[0].price),
        startDate: followingPhase.start_date,
      };
    }
  }

  if (!planSubscription) {
    return <CSpinner />;
  }

  return (
    <div>
      <div>
        <h3>Plan</h3>
        <CCard>
          <CCardBody>
            <Subscription
              status={[
                !planSubscription.cancel_at_period_end && subscriptions.ACTIVE,
              ]}
            >
              {followingPlan ? (
                <CAlert color="info">
                  Your plan subscription will be switched to{' '}
                  {followingPlan.name} plan after{' '}
                  {DateTime.fromSeconds(followingPlan.startDate).toLocaleString(
                    DateTime.DATE_FULL
                  )}
                  .
                </CAlert>
              ) : null}
              <CRow alignVertical="items-center">
                <CCol xs="12" lg="6">
                  {currentPlan && (
                    <div className="mb-3 mb-lg-0">
                      <Plan
                        name={currentPlan.name}
                        price={currentPlan.price.unit_amount / 100}
                        limit={currentPlan.limit}
                        period={currentPlan.price.recurring.interval}
                      />
                    </div>
                  )}
                </CCol>
                <CCol lg="6">
                  {/* <CButton
                    variant="outline"
                    color="primary"
                    block
                    onClick={openUpdatePlanSubscriptionOverlay}
                  >
                    Change plan
                  </CButton> */}
                  <CButton block onClick={openCancelPlanSubscriptionOverlay}>
                    Cancel plan
                  </CButton>
                </CCol>
              </CRow>
            </Subscription>

            <Subscription
              status={[
                planSubscription.cancel_at_period_end && subscriptions.ACTIVE,
              ]}
            >
              <CAlert color="warning">
                Your plan subscription will end on{' '}
                <strong>
                  {DateTime.fromSeconds(
                    planSubscription.current_period_end
                  ).toLocaleString(DateTime.DATE_FULL)}
                </strong>{' '}
                without renewal.
                <br />
                <strong>
                  Video playback will be disabled and your videos will be
                  deleted after 30 days if you won&apos;t resubscribe.
                </strong>
              </CAlert>
              <CRow alignVertical="items-center">
                <CCol xs="12" lg="6">
                  {currentPlan && (
                    <div className="mb-3 mb-lg-0">
                      <Plan
                        name={currentPlan.name}
                        price={currentPlan.price.unit_amount / 100}
                        limit={currentPlan.limit}
                        period={currentPlan.price.recurring.interval}
                      />
                    </div>
                  )}
                </CCol>
                <CCol lg="6">
                  <CButton
                    variant="outline"
                    color="primary"
                    block
                    onClick={openResubscribeOverlay}
                  >
                    Resubscribe
                  </CButton>
                </CCol>
              </CRow>
            </Subscription>

            <Subscription status={[subscriptions.PAST_DUE]}>
              <CRow>
                <CCol>
                  <CAlert color="danger">
                    Your plan is currently on hold and is about to be canceled
                    if you don&apos;t update your payment method.
                  </CAlert>
                </CCol>
              </CRow>
              <CRow alignVertical="items-center">
                <CCol xs="12" lg="6">
                  {currentPlan && (
                    <div className="mb-3 mb-lg-0">
                      <Plan
                        name={currentPlan.name}
                        price={currentPlan.price.unit_amount / 100}
                        limit={currentPlan.limit}
                        period={currentPlan.price.recurring.interval}
                      />
                    </div>
                  )}
                </CCol>
                <CCol>
                  <CButton block onClick={openCancelPlanSubscriptionOverlay}>
                    Cancel plan
                  </CButton>
                </CCol>
              </CRow>
            </Subscription>

            <Subscription status={[subscriptions.CANCELED]}>
              <CRow alignVertical="items-center">
                <CCol xs="12" lg="6">
                  <div className="mb-3 mb-lg-0">
                    Your plan was canceled. You can create a new subscription.
                  </div>
                </CCol>
                <CCol lg="6">
                  <CButton
                    variant="outline"
                    color="primary"
                    block
                    onClick={() => router.push('/subscribe')}
                  >
                    Subscribe
                  </CButton>
                </CCol>
              </CRow>
            </Subscription>
          </CCardBody>
        </CCard>
      </div>
      <div>
        <h3>Payment details</h3>
        <CCard>
          <CCardBody>
            <Subscription
              status={[subscriptions.ACTIVE, subscriptions.PAST_DUE]}
            >
              <CRow alignVertical="items-center">
                <CCol xs="12" lg="6">
                  <CCard color="light" className="mb-3 border-0 mb-lg-0">
                    <CCardBody>
                      <CRow>
                        <CCol xs="auto">
                          <CIcon name="cil-credit-card" size="2xl" />
                        </CCol>
                        <CCol>
                          <h4>
                            ••••{' '}
                            {
                              planSubscription?.default_payment_method?.card
                                .last4
                            }
                          </h4>
                          <p className="mb-0">
                            Expires{' '}
                            {
                              planSubscription?.default_payment_method?.card
                                .exp_month
                            }
                            /
                            {
                              planSubscription?.default_payment_method?.card
                                .exp_year
                            }
                          </p>
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol lg="6">
                  <CButton
                    color="primary"
                    variant="outline"
                    block
                    onClick={openUpdateCardOverlay}
                  >
                    Edit card
                  </CButton>
                </CCol>
              </CRow>
            </Subscription>

            <Subscription status={[subscriptions.CANCELED]}>
              <CRow>
                <CCol>
                  <div>No card linked.</div>
                </CCol>
              </CRow>
            </Subscription>
          </CCardBody>
        </CCard>
      </div>
    </div>
  );
};

export default Billing;
