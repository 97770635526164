import React from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  CButton,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CCol,
  CSpinner,
} from '@coreui/react';

import FormField from './FormField';
import CardField from './CardField';
import withFormResult from './withFormResult';

import { createSetupIntent } from '../services/setupIntent';
import { updatePlanSubscription } from '../services/planSubscription';
import { usePlanSubscription } from '../hooks';

const schema = yup.object().shape({
  cardholder_name: yup.string().label('Name').required(),
  card: yup.boolean().isTrue('Card information required'),
});

const UpdateCardForm = ({ setSuccessResult, setErrorResult, resetResult }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { refreshPlanSubscription } = usePlanSubscription();

  // external Stripe scripts must be loaded
  const isFormReady = Boolean(stripe && elements);

  const handleFormSubmit = async ({ cardholder_name }, actions) => {
    resetResult();

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    try {
      const setupIntent = await createSetupIntent();

      const { error, setupIntent: completedSetupIntent } =
        await stripe.confirmCardSetup(setupIntent.client_secret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: cardholder_name,
            },
          },
        });

      if (error) {
        throw error;
      }

      await updatePlanSubscription({
        paymentMethodId: completedSetupIntent.payment_method,
      });

      actions.resetForm();
      cardElement.clear();

      refreshPlanSubscription();
      setSuccessResult('Update successful.');
    } catch (error) {
      setErrorResult(error.message || 'Update failed.');
    }
  };

  return (
    <Formik
      initialValues={{
        cardholder_name: '',
        card: false,
      }}
      validationSchema={schema}
      onSubmit={handleFormSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <CForm onSubmit={handleSubmit}>
          <CRow>
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="cardholder-name">Name on card</CLabel>
                <FormField name="cardholder_name">
                  <CInput id="cardholder-name" type="text" />
                </FormField>
              </CFormGroup>
            </CCol>
          </CRow>
          <CFormGroup>
            <CLabel>Card information</CLabel>
            <CardField name="card" />
          </CFormGroup>
          <CRow alignHorizontal="center">
            <CCol>
              <CButton
                type="submit"
                variant="outline"
                color="primary"
                className="d-block w-100"
                disabled={!isFormReady || isSubmitting}
              >
                {!isFormReady || isSubmitting ? <CSpinner /> : 'Update'}
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      )}
    </Formik>
  );
};

export default withFormResult(UpdateCardForm);
